import axios from "axios";
import "../../config";

const API_URL = global.config.API_URL + 'pool/';



const createPools = (
  name,
  type,
  format,
  description,
  defaultBuy_in_amount,
  goal_amount,
  maxMembers,
  pickDeadline,
  votingDeadline,
  photo_id,
  group,
  is_private) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL, {
    name,
    type,
    format,
    description,
    defaultBuy_in_amount,
    goal_amount,
    maxMembers,
    pickDeadline,
    votingDeadline,
    photo_id,
    group,
    is_private
  }, { headers });
};

const getPoolsByUserId = (userId) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "fetch-by-ownerId/" + userId, { headers });
}

const getPools = () => {
  return axios.get(API_URL);
}

const getBettingsPoolTypesAndFormats = () => {
  return axios.get(API_URL + "types-formats");
}

const getPoolsById = (id) => {
  return axios.get(API_URL + id);
}

const addMemberToPool = (userId, poolId) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + poolId + "/join/" + userId, {
  }, { headers });
}

const getPoolsInWhichIamJoined = (userId) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "joined/" + userId + "/", { headers });
}

const inviteNotification = (recipients, id) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + id + "/invite", {
    recipients
  }, { headers });
}

const addOrUpdatePoolSettings = (PoolsID, name, description, defaultBuy_in_amount, goal_amount, maxMembers, pickDeadline, votingDeadline, modifiedBy, photo_id, is_private) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.put(API_URL + PoolsID, {
    name,
    description,
    defaultBuy_in_amount,
    goal_amount,
    maxMembers,
    pickDeadline,
    votingDeadline,
    modifiedBy,
    photo_id,
    is_private
  }, { headers });
}

const createPoolsMessage = (PoolsID, message, createdBy) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "message", {
    PoolsID,
    message,
    createdBy
  }, { headers });
}

const createPoolsEventMessage = (PoolsEventID, message, createdBy) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "event-message", {
    PoolsEventID,
    message,
    createdBy
  }, { headers });
}

const getPoolsInWhichIamJoinedWithMessage = (userId) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "joined/" + userId + "/?expand=messages", { headers });
}

const getNotificationsbyUserId = (userToNotify) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "notifications-by-userId/" + (userToNotify), { headers });
}

const addWinnerForPool = (bettingPoolID, winnerId, createdBy, amount) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "winner", {
    bettingPoolID,
    winnerId,
    createdBy,
    amount
  }, { headers });
}

const getWinnersByPools = (PoolsID) => {
  return axios.get(API_URL + "winner/" + PoolsID);
}

const addVoteForWinner = (bettingPoolID, winnerId, isYes, createdBy) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "winner-vote", {
    bettingPoolID,
    winnerId,
    isYes,
    createdBy
  }, { headers });
}

const updateFinalWinner = (PoolsID, winnerId) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.put(API_URL + "winner-final/" + PoolsID + "/" + winnerId, { headers });
}

const getFinalWinnerByPools = (PoolsID) => {
  return axios.get(API_URL + "winner-final/" + PoolsID);
}

const createPoolDeleteRequest = (bettingPoolID, createdBy) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "delete", {
    bettingPoolID,
    createdBy
  }, { headers });
}

const haveVotedForPool = (PoolsID, userId) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "validate-vote/" + PoolsID + "/" + userId, { headers });
}

const getDeleteRequestByPoolId = (PoolsID) => {
  return axios.get(API_URL + "delete/" + PoolsID);
}

const createPoolsRefundRequests = (bettingPoolID, createdBy) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "refund", {
    bettingPoolID,
    createdBy
  }, { headers });
}

const getPoolsRefundRequests = (PoolsID) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "refund/" + PoolsID, { headers });
}

const updatePoolsStatus = (PoolsID, status) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "status/" + PoolsID + "/" + status, { headers });
}

const makeMemberAdmin = (bettingPoolID, memberID) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "member-role", {
    bettingPoolID,
    memberID
  }, { headers });
}

const deleteNotificationById = (Id) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "notification-delete", {
    Id
  }, { headers });
}

const addPoolsEvent = (name, description, visibility, address, city, state, maxMembersInPool, maxPool, eventDate, photo_id, createdBy, website) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "event", {
    name,
    description,
    visibility,
    address,
    city,
    state,
    maxMembersInPool,
    maxPool,
    eventDate,
    photo_id,
    createdBy,
    website
  }, { headers });
}

const getPoolsEvents = () => {
  return axios.get(API_URL + "event");
}

const getPoolsEventById = (id) => {
  return axios.get(API_URL + "event/" + id);
}

const createEventPool = (poolsInEvent, PoolsEventID) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "event/add", {
    poolsInEvent,
    PoolsEventID
  }, { headers });
}

const getPoolsByEventId = (id) => {
  return axios.get(API_URL + "fetch-by-event/" + id);
}

const inviteEventNotification = (recipients, id) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "event/invite", {
    id,
    recipients
  }, { headers });
}

const addEventTips = (userId, eventId, response) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "event-tips", {
    userId, eventId, response
  }, { headers });
}

const getTotalPoolPaymentInEventByMonths = (id) => {
  return axios.get(API_URL + "event/report/" + id + "/?group-by=payment&date_facet=month");
}

const getTotalPoolInEventPaymentByWeek = (id) => {
  return axios.get(API_URL + "event/report/" + id + "/?group-by=payment&date_facet=week");
}

const getTotalTipsInEventByMonths = (id) => {
  return axios.get(API_URL + "event-tips/report/" + id + "/?group-by=payment&date_facet=month");
}

const getTotalTipsInEventPaymentByWeek = (id) => {
  return axios.get(API_URL + "event-tips/report/" + id + "/?group-by=payment&date_facet=week");
}

const updatePoolsEvent = (id, name, description, visibility, address, city, state, maxMembersInPool, maxPool, eventDate, photo_id, createdBy, website) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "event/update", {
    id,
    name,
    description,
    visibility,
    address,
    city,
    state,
    maxMembersInPool,
    maxPool,
    eventDate,
    photo_id,
    createdBy,
    website
  }, { headers });
}

const leavePools = (userId, PoolID) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "leave", {
    userId, PoolID
  }, { headers });
}

const getBettingPoolMessage = (id) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + id + "/message", { headers });
}

const getPoolEventMessage = (id) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "event-message/" + id + "/message", { headers });
}

const getPoolFinacialActivities = (id) => {
  return axios.get(API_URL + id + "/activities");
}

const filterPools = (term, joinedByMe, createdByMe, isClosed, isOpened, orderBy) => {
  return axios.get(API_URL + "?term=" + term + "&joinedByMe=" + joinedByMe + "&createdByMe=" + createdByMe + "&isClosed=" + isClosed + "&isOpened=" + isOpened + "&orderBy=" + orderBy);
}

const filterEvents = (term) => {
  return axios.get(API_URL + "event?term=" + term);
}



const PoolService = {
  createPools,
  getPoolsByUserId,
  getBettingsPoolTypesAndFormats,
  getPools,
  getPoolsById,
  addMemberToPool,
  getPoolsInWhichIamJoined,
  inviteNotification,
  addOrUpdatePoolSettings,
  createPoolsMessage,
  getPoolsInWhichIamJoinedWithMessage,
  getNotificationsbyUserId,
  addWinnerForPool,
  getWinnersByPools,
  addVoteForWinner,
  updateFinalWinner,
  getFinalWinnerByPools,
  createPoolDeleteRequest,
  haveVotedForPool,
  getDeleteRequestByPoolId,
  createPoolsRefundRequests,
  getPoolsRefundRequests,
  updatePoolsStatus,
  makeMemberAdmin,
  deleteNotificationById,
  addPoolsEvent,
  getPoolsEvents,
  getPoolsEventById,
  createEventPool,
  getPoolsByEventId,
  inviteEventNotification,
  addEventTips,
  getTotalPoolPaymentInEventByMonths,
  getTotalPoolInEventPaymentByWeek,
  getTotalTipsInEventByMonths,
  getTotalTipsInEventPaymentByWeek,
  updatePoolsEvent,
  leavePools,
  createPoolsEventMessage,
  getBettingPoolMessage,
  getPoolFinacialActivities,
  getPoolEventMessage,
  filterPools,
  filterEvents
}



export default PoolService;