import classNamees from './dashboard.module.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import $ from 'jquery';
import Moment from 'moment';
import TimeAgo from 'react-timeago';


import NavigationBar from '../../components/navigation/navigation';
import { setProgress } from "../../slices/loader";
import { convertUTCDateToLocalDate } from '../../helpers/dateTimeHelper';
import PoolService from "../../services/pool.service";
import ManagePaymentService from "../../services/managePayment.service";
import EventBus from "../../common/eventBus";


const Dashboard = () => {
    const searchParams = useLocation();
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { progress } = useSelector((state) => state.loader);
    const [content, setContent] = useState("");
    const [userPoolData, setUserPoolData] = useState([]);
    const [joinedPoolData, setJoinedPoolData] = useState([]);
    const [userNotifications, setUserNotifications] = useState([]);
    const [monthChart, setMonthChart] = useState([]);
    const [monthChartTicks, setMonthChartTicks] = useState([]);
    const [weekChart, setWeekChart] = useState([]);
    const [weekChartTicks, setWeekChartTicks] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentUser) {

            dispatch(setProgress(progress + 50));
            PoolService.getPoolsInWhichIamJoined(currentUser.user.id).then(
                (response) => {
                    setJoinedPoolData(response.data.response_body.Pools);
                    dispatch(setProgress(100));
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    dispatch(setProgress(100));

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );


            dispatch(setProgress(progress + 50));

            PoolService.getPoolsByUserId(currentUser.user.id).then(
                (response) => {
                    setUserPoolData(response.data.response_body.Pools);
                    dispatch(setProgress(100));
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    dispatch(setProgress(100));

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );

            dispatch(setProgress(progress + 50));

            PoolService.getNotificationsbyUserId(currentUser.user.id).then(
                (response) => {

                    if (response.data.response_body.Notifications.length > 0) {
                        setUserNotifications(response.data.response_body.Notifications);
                    }
                    dispatch(setProgress(100));
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    dispatch(setProgress(100));

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );

            dispatch(setProgress(progress + 50));
            ManagePaymentService.getTotalPoolPaymentByMonths(currentUser.user.id).then(
                (response) => {

                    let chartData = response.data.response_body.Contribution;
                    setMonthChart([["Month", "Contributed"], ...chartData]);
                    let maxTicksValue = Math.max(...chartData.map(x => x[1]));
                    let ticks = [];
                    for (var i = 0; i <= maxTicksValue; i = i + 5) {
                        ticks.push(i);
                    }
                    if (maxTicksValue == 0) {
                        ticks = [0, 10, 20, 30];
                    }
                    setMonthChartTicks(ticks);
                    dispatch(setProgress(100));
                },
                (error) => {

                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    dispatch(setProgress(100));

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );

            dispatch(setProgress(progress + 50));
            ManagePaymentService.getTotalPoolPaymentByWeek(currentUser.user.id).then(
                (response) => {

                    let chartData = response.data.response_body.Contribution;
                    setWeekChart([["Week", "Previous", "Current"], ...chartData]);
                    let maxTicksValue = Math.max(...chartData.map(x => x[1]));
                    let ticks = [];
                    for (var i = 0; i <= maxTicksValue; i = i + 5) {
                        ticks.push(i);
                    }
                    if (maxTicksValue == 0) {
                        ticks = [0, 10, 20, 30];
                    }
                    setWeekChartTicks(ticks);
                    dispatch(setProgress(100));
                },
                (error) => {

                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                    dispatch(setProgress(100));

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );
        }
    }, [currentUser]);

    const handlePoolSelection = function (pool) {
        var is_private = pool.is_private;

        if (is_private && currentUser) {
            var members = pool.PoolsMembers.filter(x => x.memberID == currentUser.user.id);
            if (members.length > 0) {
                navigate({
                    pathname: '/pool-details',
                    search: '?id=' + pool.id,
                });
            }
        } else {
            navigate({
                pathname: '/pool-details',
                search: '?id=' + pool.id,
            });
        }
    }

    if (!currentUser) {
        let formatReturnUrl = encodeURIComponent(`${searchParams.pathname}${searchParams.search}`);
        let returnUrl = `?returnUrl=${formatReturnUrl}`;
        return <Navigate to={`/login${returnUrl}`} />;
    }

    return (
        < >

            <main>
                <div className="cip-dashboard-body">

                    <NavigationBar props={{ type: 0, pageName: "Dashboard" }} />

                    <div className="dashboard-graph-area">
                        <div className="dashboard-graph">
                            <Chart
                                chartType="AreaChart"
                                width="100%"
                                height="500px"
                                data={monthChart}
                                options={{
                                    title: "Total money in pool",
                                    hAxis: { title: "Month", titleTextStyle: { color: "#BDC2C6" }, color: "#BDC2C6" },
                                    vAxis: { ticks: monthChartTicks, minValue: 0, },
                                    chartArea: { width: "80%", height: "80%" },
                                    colors: ['#c5914b'],
                                    legend: "none",
                                    lineWidth: 3,
                                    animation: {
                                        startup: true,
                                        easing: "linear",
                                        duration: 1500,
                                    },
                                    responsive: true,
                                }}
                                chartEvents={[
                                    {
                                        eventName: "ready",
                                        callback: ({ chartWrapper, google }) => {

                                        }
                                    }
                                ]}
                            />
                        </div>

                        <div className="dashboard-graph">
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="500px"
                                data={weekChart}
                                options={{
                                    title: "Total money in pool",
                                    chartArea: { width: "80%", height: "80%" },
                                    isStacked: true,
                                    legend: "none",
                                    bar: { groupWidth: "10%" },
                                    hAxis: {
                                        title: "Week",
                                        minValue: 0,
                                    },
                                    vAxis: {
                                        title: "Contributed",
                                        ticks: weekChartTicks,
                                        minValue: 0,
                                    },
                                    colors: ['#FFC371', '#FF796E'],
                                    animation: {
                                        startup: true,
                                        easing: "linear",
                                        duration: 1500,
                                    },
                                    responsive: true,
                                }}
                                chartEvents={[
                                    {
                                        eventName: "ready",
                                        callback: ({ chartWrapper, google }) => {

                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>

                    <div className="dashboard-pools-area">
                        <div className="dashboard-my-pools">
                            <div className="dashboard-card-header">
                                <h2 className="text-title-2">My Pools</h2>
                                <Link className="text-title-4" to={"/pools"}>See all</Link>
                            </div>
                            <div className="my-pools-list">
                                {(userPoolData) && userPoolData.slice(0, 2).map((row, i) => {
                                    return (<div className="pool-item" key={i} >
                                        <div className="pool-thumbnail">
                                            <img src="assets/img/pool-thumbnails/pool-1.png" alt="pool-thumbnail" />
                                        </div>
                                        <div className="d-block w-100">
                                            <div className="pool-elements">
                                                <div className="pool-header">
                                                    <div className="pool-title-badge">
                                                        <h3 className="text-title-2" style={{ wordWrap: 'break-word', inlineSize: '150px' }}><a href="#" onClick={() => handlePoolSelection(row)}>{row.name}</a></h3>
                                                        <div className="pool-badges" style={{ display: 'table-row' }}>
                                                            {
                                                                ((Object.keys(row).length > 0)) && (row.is_private == 0) && (
                                                                    <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Private</span>
                                                                )
                                                            }
                                                            {
                                                                ((Object.keys(row).length > 0)) && (row.is_private == 1) && (
                                                                    <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Public</span>
                                                                )
                                                            }
                                                            {
                                                                (Object.keys(row).length > 0) && (row.PoolsMembers.length > 0) &&
                                                                (row.PoolsMembers.filter(member => member.memberID === currentUser.user.id).length > 0) && (
                                                                    <span className="badge bg-success" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Joined</span>
                                                                )
                                                            }
                                                            {
                                                                ((Object.keys(row).length > 0)) && (row.ownerID == currentUser.user.id) && (
                                                                    <span className="badge bg-success" style={{ height: 'fit-content', marginLeft: '7px', marginTop: '5px' }}>Commissioner</span>
                                                                )
                                                            }
                                                            {
                                                                (Object.keys(row).length > 0) && (row.ownerID == currentUser.user.id) && (
                                                                    <span className="badge bg-success" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Owner</span>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="pool-options">
                                                        <button>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16667 9.53975 9.16667 9.99999C9.16667 10.4602 9.53977 10.8333 10 10.8333Z"
                                                                    stroke="#67717C" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                                <path
                                                                    d="M10 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 10 3.33334C9.53977 3.33334 9.16667 3.70644 9.16667 4.16668C9.16667 4.62691 9.53977 5.00001 10 5.00001Z"
                                                                    stroke="#67717C" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                                <path
                                                                    d="M10 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 10 15C9.53977 15 9.16667 15.3731 9.16667 15.8333C9.16667 16.2936 9.53977 16.6667 10 16.6667Z"
                                                                    stroke="#67717C" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="pool-footer">
                                                    <div className="pool-currency d-flex justify-content-between">
                                                        <h4 className="text-title-4">{row.PoolsMembers.length > 0 ? "$" + row.PoolsMembers.reduce((accumulator, current) => { return accumulator + parseFloat(current.contributed); }, 0) : "$0"}</h4>
                                                        <h4 className="text-body-3">${row.goal_amount}</h4>
                                                    </div>
                                                    <div className="pool-progressbar">
                                                        <div className="pool-progress" style={{ width: "40%" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>

                        <div className="dashboard-joined-pools">
                            <div className="dashboard-card-header">
                                <h2 className="text-title-2">Joined Pools</h2>
                                <Link className="text-title-4" to={"/pools"}>See all</Link>
                            </div>
                            <div className="my-pools-list">
                                {(joinedPoolData) && joinedPoolData.slice(0, 2).map((row, i) => {
                                    return (<div className="pool-item" key={i} >
                                        <div className="pool-thumbnail">
                                            <img src="assets/img/pool-thumbnails/pool-3.png" alt="pool-thumbnail" />
                                        </div>
                                        <div className="d-block w-100">
                                            <div className="pool-elements">
                                                <div className="pool-header">
                                                    <div className="pool-title-badge">
                                                        <h3 className="text-title-2" style={{ wordWrap: 'break-word', inlineSize: '150px' }}><a href='#' onClick={() => handlePoolSelection(row)}>{row.name}</a></h3>
                                                        <div className="pool-badges" style={{ display: 'table-row' }}>
                                                            {
                                                                ((Object.keys(row).length > 0)) && (row.is_private == 0) && (
                                                                    <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Private</span>
                                                                )
                                                            }
                                                            {
                                                                ((Object.keys(row).length > 0)) && (row.is_private == 1) && (
                                                                    <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Public</span>
                                                                )
                                                            }
                                                            {
                                                                (Object.keys(row).length > 0) && (row.PoolsMembers.length > 0) &&
                                                                (row.PoolsMembers.filter(member => member.memberID === currentUser.user.id).length > 0) && (
                                                                    <span className="badge bg-success" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Joined</span>
                                                                )
                                                            }
                                                            {
                                                                ((Object.keys(row).length > 0)) && (row.ownerID == currentUser.user.id) && (
                                                                    <span className="badge bg-success" style={{ height: 'fit-content', marginLeft: '7px', marginTop: '5px' }}>Commissioner</span>
                                                                )
                                                            }
                                                            {
                                                                (Object.keys(row).length > 0) && (row.ownerID == currentUser.user.id) && (
                                                                    <span className="badge bg-success" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Owner</span>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="pool-options">
                                                        <button>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16667 9.53975 9.16667 9.99999C9.16667 10.4602 9.53977 10.8333 10 10.8333Z"
                                                                    stroke="#67717C" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                                <path
                                                                    d="M10 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 10 3.33334C9.53977 3.33334 9.16667 3.70644 9.16667 4.16668C9.16667 4.62691 9.53977 5.00001 10 5.00001Z"
                                                                    stroke="#67717C" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                                <path
                                                                    d="M10 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 10 15C9.53977 15 9.16667 15.3731 9.16667 15.8333C9.16667 16.2936 9.53977 16.6667 10 16.6667Z"
                                                                    stroke="#67717C" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="pool-footer">
                                                    <div className="pool-currency d-flex justify-content-between">
                                                        <h4 className="text-title-4">{row.PoolsMembers.length > 0 ? "$" + row.PoolsMembers.reduce((accumulator, current) => { return accumulator + parseFloat(current.contributed); }, 0) : "$0"}</h4>
                                                        <h4 className="text-body-3">${row.goal_amount}</h4>
                                                    </div>
                                                    <div className="pool-progressbar">
                                                        <div className="pool-progress" style={{ width: "40%" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>

                        <div className="dashboard-latest-updates">
                            <div className="dashboard-card-header">
                                <h2 className="text-title-2">Latest Updates</h2>
                                <Link className="text-title-4" to={"/notifications"}>See all</Link>
                            </div>
                            <ul className="notification-items mb-0">

                                {userNotifications?.map((item, index) => {
                                    return (<li key={index}>
                                        <a href="#">
                                            <p className="text-body-2 mb-0"> {item.message}</p>
                                            <TimeAgo date={convertUTCDateToLocalDate(new Date(item.createdAt))} style={{ float: "right" }} />
                                        </a>
                                    </li>)
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Dashboard;
